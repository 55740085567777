





















const node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProtocolProspectus_protocol",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prospectus",
      "storageKey": null
    }
  ],
  "type": "Protocol",
  "abstractKey": null
};

(node ).hash = "ee4cceaf7874109d45b754e33d20be7d";

export default node;

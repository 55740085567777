import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import dayjs from '../utils/dayjs'

const Journal = () => {
  const navigate = useNavigate()
  const isoDate = dayjs().format('YYYYMMDD')
  useEffect(() => {
    navigate(`/journal/${isoDate}`)
  }, [])
  return null
}

export default Journal

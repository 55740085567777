import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime";import logo from '../../public/assets/images/logo.svg'
import NavDiscover from './NavDiscover'
import NavJournal from './NavJournal'
import NavProfile from './NavProfile'

const LeftNav = () => {
  return (
    _jsxs('div', { className: "hidden h-full w-52 shrink-0 flex-col justify-start align-top dark:bg-black md:flex"        , children: [
      _jsx('img', { src: logo, className: 'h-fit',} )
      , _jsx(NavJournal, {} )
      , _jsx(NavDiscover, {} )
      , _jsx(NavProfile, {} )
    ]})
  )
}

export default LeftNav

import {jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";import * as Label from '@radix-ui/react-label'
import * as ScrollArea from '@radix-ui/react-scroll-area'
// import graphql from 'babel-plugin-relay/macro'
import {GrUser} from '@react-icons/all-files/gr/GrUser'
import {useRef} from 'react'







const Profile = (props) => {
  const {queryRef} = props
  const ref = useRef(null)
  return (
    _jsx('div', { className: "relative flex h-full w-full flex-col"    , children: 
      /* https://github.com/framer/motion/issues/1524 */
      _jsxs(ScrollArea.Root, { type: "hover", className: "overflow-hidden", children: [
        _jsxs(ScrollArea.Viewport, {
          ref: ref,
          className: 'flex h-full w-full flex-col [&>*]:!flex [&>*]:!flex-col',
 children: [
          _jsx('div', { className: "flex h-14 shrink-0"  ,})
          , _jsx('header', { className: "absolute top-0 left-0 z-10 w-full p-4 text-center font-bold backdrop-blur-xl"        , children: 
            _jsx('h1', { children: "Profile"})
          })
          , _jsxs('div', { className: "flex items-center p-4"  , children: [
            _jsx('div', { className: "flex h-28 w-28 items-center justify-center rounded-full text-4xl shadow-lg dark:bg-dark-surface2 dark:text-dark-onBackground"         , children: 
              _jsx(GrUser, { className: "[&>*]:stroke-dark-onSurface",} )
            })
            , _jsx('div', { className: "flex-1 items-center self-center justify-self-center pl-2 font-bold"     , children: "123456"

            })
          ]})
          , _jsxs('div', { className: "p-4", children: [
            _jsx(Label.Root, { className: "p-2", htmlFor: "userId", children: "User ID"

            })
            , _jsx('input', {
              disabled: true,
              className: "w-fit dark:bg-dark-surface2" ,
              type: "text",
              id: "userId",
              defaultValue: "123456",}
            )
            , _jsx(Label.Root, { className: "p-2", htmlFor: "email", children: "Email"

            })
            , _jsx('input', {
              disabled: true,
              className: "w-fit dark:bg-dark-surface2" ,
              type: "text",
              id: "email",
              defaultValue: "a@fakedomain.com",}
            )
          ]})
        ]})
        , _jsx(ScrollArea.Scrollbar, {
          orientation: "vertical",
          className: "!top-14 flex w-2 touch-none select-none opacity-50 transition-opacity"      ,
 children: 
          _jsx(ScrollArea.Thumb, { className: "flex-1 rounded-lg dark:bg-dark-surfaceTint"  ,} )
        })
      ]})
    })
  )
}

export default Profile

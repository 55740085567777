import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {MdArrowBack} from '@react-icons/all-files/md/MdArrowBack'

import {useEffect, useState} from 'react'
import {graphql, useMutation, usePreloadedQuery} from 'react-relay'
import {useNavigate} from 'react-router-dom'
import YouTube from 'react-youtube'


import theme from '../utils/theme'
import ProtocolProspectus from './ProtocolProspectus'





const Protocol = (props) => {
  const {queryRef} = props
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const data = usePreloadedQuery(
    graphql`
      query ProtocolQuery($slug: String!) {
        protocol(slug: $slug) {
          ...ProtocolProspectus_protocol
          id
          title
          slug
          backgroundSrc
          videoSrc
          videoProvider
          videoStartAt
          videoEndAt
          duration
          track
          trackAvatar
          priceRange
          topTrait
          createdAt
          categories {
            id
            name
          }
        }
      }
    `,
    queryRef
  )
  const protocol = data.protocol
  const {
    backgroundSrc,
    id: protocolId,
    title,
    duration,
    slug,
    track,
    trackAvatar,
    priceRange,
    topTrait,
    createdAt,
    categories,
    videoSrc,
    videoEndAt,
    videoStartAt,
    videoProvider
  } = protocol
  const [commit, isInFlight] = useMutation(graphql`
    mutation ProtocolStartExperimentMutation($protocolId: ID!) {
      startExperiment(protocolId: $protocolId) {
        error
        experiment {
          id
        }
      }
    }
  `)
  useEffect(() => {
    document.title = `${title} | Spearmint`
  }, [])
  const trait = topTrait ? _optionalChain([topTrait, 'access', _ => _[0], 'optionalAccess', _2 => _2.toUpperCase, 'call', _3 => _3()]) + topTrait.slice(1) : null
  return (
    _jsxs('div', { className: 'flex h-full w-full flex-col text-white dark:bg-dark-surface', children: [
      _jsx(MdArrowBack, {
        className: "absolute top-2 left-2 text-4xl text-white"    ,
        onClick: () => navigate(-1),}
      )
      , _jsxs('div', {
        id: "protocol-header",
        className: `grid w-full gap-2 bg-cover bg-center p-4 grid-areas-p-mobile md:grid-cols-2 md:flex-row md:justify-start md:gap-4 md:grid-areas-p-desktop`,
        style: {
          backgroundImage: `linear-gradient(rgba(0,0,0,.5) 0%, ${theme.colors.dark.surface} 100%), url(${backgroundSrc})`
        },
 children: [
        false && videoSrc && videoProvider === 'youtube' && (
          _jsx(YouTube, {
            videoId: videoSrc,
            opts: {playerVars: {start: videoStartAt, end: videoEndAt}},
            iframeClassName: 'w-full aspect-video h-fit',
            className: 'flex w-full self-center justify-self-center pt-10 grid-in-video',}
          )
        )
        , _jsx('div', { className: "flex items-center pt-2 grid-in-title md:items-end"    , children: 
          _jsxs('div', { className: "flex items-center" , children: [
            _jsx('h1', { className: 'text-xl font-bold dark:text-dark-onSurface md:text-4xl', children: title})
            , trait && (
              _jsx('span', { className: "ml-2 rounded px-1 text-xs dark:bg-dark-tertiary dark:text-dark-onTertiary"     , children: 
                trait
              })
            )
          ]})
        })
        , _jsxs('div', { className: "grid-in-meta", children: [
          _jsxs('div', { className: 'flex pt-2 text-sm text-light-onSurface dark:text-dark-onSurface', children: [
            _jsx('img', {
              className: 'mr-2 rounded-full',
              draggable: false,
              alt: track,
              src: trackAvatar,
              width: 24,
              height: 24,}
            )
            , _jsx('div', {
              className: "flex select-none flex-col items-center justify-center text-xs font-light dark:text-dark-onSurfaceVariant"       ,
              draggable: false,
 children: 
              track
            })
          ]})
          , _jsx('div', { className: "text-xs dark:text-dark-onSurfaceVariant" , children: `${duration} · ${priceRange}`})
          , categories.map((category) => {
            const {name} = category
            return (
              _jsx('span', {

                className: "w-fit rounded-full px-2.5 py-0.5 text-xs font-semibold dark:bg-dark-secondary dark:text-dark-onSecondary"       ,
 children: 
                name
              }, name)
            )
          })
        ]})

        /* <div id='protocol-duration' className='inline-flex items-center text-xs font-light '>
            <MdTimelapse className='mr-2 h-6 w-6' />
            <span className='dark:text-dark-onSurfaceVariant'>{`${duration}`}</span>
          </div> */
        , _jsxs('div', { className: "flex justify-end grid-in-cta md:justify-start"   , children: [
          _jsx('button', {
            onClick: 
              () => {
                navigate(`/kickoff/${slug}`)
              }
              // () => router.push('')
              // commit({
              //   variables: {protocolId},
              //   onCompleted: ({startExperiment}) => {
              //     const {error, experiment} = startExperiment
              //     if (error) setError(error)
              //     if (experiment) {
              //       const {id: experimentId} = experiment
              //       router.push(`/experiments/${slug}-${experimentId}`)
              //     }
              //   }
              // })
            ,
            disabled: isInFlight,
            className: "w-fit self-center rounded-full p-4 py-2 text-lg font-medium uppercase dark:bg-dark-primary dark:text-dark-onPrimary"         ,
 children: "Join Experiment"

          })
          , error && _jsx('div', { children: error})
        ]})
      ]})
      , _jsx(ProtocolProspectus, { protocolKey: protocol,} )
    ]})
  )
}

export default Protocol

import {jsx as _jsx} from "react/jsx-runtime";import {GrCalendar} from '@react-icons/all-files/gr/GrCalendar'

import BottomNavButton from './BottomNavButton'
import LeftNavItem from './LeftNavItem'



const NavJournal = (props) => {
  const {isBottom} = props
  const href = '/journal'
  const activeRoutes = ['/journal', '/experiments']
  const activeIcon = _jsx(GrCalendar, { className: "[&>*]:stroke-dark-onSurface",} )
  const inactiveIcon = _jsx(GrCalendar, { className: "[&>*]:stroke-dark-outline",} )
  const name = 'Journal'
  const itemProps = {href, activeRoutes, activeIcon, inactiveIcon, name}
  return isBottom ? _jsx(BottomNavButton, { ...itemProps,} ) : _jsx(LeftNavItem, { ...itemProps,} )
}

export default NavJournal

import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime";import {FiChevronRight} from '@react-icons/all-files/fi/FiChevronRight'
import {m} from 'framer-motion'

import {useLayoutEffect, useRef, useState} from 'react'

import {graphql, useFragment} from 'react-relay'

import useTransformRestoration from '../hooks/useTransformRestoration'
import RowPoster from './RowPoster'





const Row = (props) => {
  const {categoryKey} = props
  const category = useFragment(
    graphql`
      fragment RowCategory on ProtocolCategory {
        title
        slug
        protocols {
          id
          ...RowPosterProtocol
        }
      }
    `,
    categoryKey
  )

  const {title, protocols, slug} = category
  const sliderRef = useRef(null)
  const constraintRef = useRef(null)
  const [left, setLeft] = useState(0)
  const [width, setWidth] = useState(0)
  useLayoutEffect(() => {
    const calcConstraint = () => {
      const el = sliderRef.current
      if (!el) return
      const {offsetWidth, scrollWidth} = el
      if (!offsetWidth || !scrollWidth) return
      setLeft(offsetWidth - scrollWidth)
      setWidth(offsetWidth + (scrollWidth - offsetWidth))
    }
    calcConstraint()
    window.addEventListener('resize', calcConstraint)
    return () => window.removeEventListener('resize', calcConstraint)
  }, [])
  const initialX = useTransformRestoration(`${slug}-X`, sliderRef, true)
  const [isDragging, setIsDragging] = useState(false)
  const onDragStart = () => {
    setIsDragging(true)
  }
  const onDragEnd = () => {
    // let click handlers come first so isDragging is true for them
    setTimeout(() => setIsDragging(false))
  }

  return (
    _jsxs('div', { className: 'block pt-2 pb-8', children: [
      _jsxs('h3', { className: 'group inline-flex pb-2 pl-4', children: [
        _jsx('p', { className: 'text-base', children: title})
        , _jsxs('p', { className: 'inline-flex translate-x-0 items-center text-xs opacity-0', children: ["Show all "
            , _jsx(FiChevronRight, {} )
        ]})
      ]})
      , _jsx('div', { className: 'group relative', children: 
        _jsxs(m.div, { className: 'relative block cursor-grab overflow-hidden', children: [
          _jsx(m.div, {
            drag: 'x',
            ref: sliderRef,
            dragConstraints: constraintRef,
            className: 'flex gap-4',
            style: {x: initialX === 0 ? 16 : initialX},
            onDragStart: onDragStart,
            onDragEnd: onDragEnd,
 children: 
            protocols.map((protocol) => {
              return _jsx(RowPoster, { isDragging: isDragging, protocolKey: protocol,}, protocol.id )
            })
          })
          , _jsx(m.div, {
            className: 'absolute',
            style: {left: left - 8, width: width + 24},
            ref: constraintRef,}
          )
        ]})
      })
    ]})
  )
}

export default Row

// const handlers = useSwipeable({onSwiped: handleSwipe})
// const navigationPrevRef = useRef<HTMLDivElement>(null)
// const navigationNextRef = useRef<HTMLDivElement>(null)
// const bulletRef = useRef<HTMLDivElement>(null)
// const slideW = 172 + 8
// const breakpoints = Object.fromEntries(
//   Array(10)
//     .fill(0)
//     .map((_, i) => [i * slideW, {slidesPerView: i + 1, slidesPerGroup: i + 1}])
// )

//   {
//     /* <Swiper
//   breakpoints={breakpoints}
//   modules={[Navigation, Pagination]}
//   navigation={{
//     prevEl: navigationPrevRef.current,
//     nextEl: navigationNextRef.current
//   }}
//   pagination={{
//     el: bulletRef.current
//   }}
//   spaceBetween={8}
//   onBeforeInit={(swiper) => {
//     const params = swiper.params as any
//     params.navigation.prevEl = navigationPrevRef.current
//     params.navigation.nextEl = navigationNextRef.current
//     params.pagination.el = bulletRef.current
//   }}
// >
//   <RowPoster isSwiper protocol={protocols[1]} />
//   {protocols.slice(2).map((protocol) => (
//     <SwiperSlide key={protocol.id} className={''}>
//       <RowPoster isSwiper protocol={protocol} />
//     </SwiperSlide>
//   ))}
//   <div
//     slot={'container-start'}
//     className={
//       'absolute top-0 z-10 flex h-full w-8 cursor-pointer items-center justify-center text-6xl text-white'
//     }
//     ref={navigationPrevRef}
//   >
//     <MdChevronLeft />
//   </div>
//   <div
//     slot={'container-end'}
//     className={
//       'absolute top-0 right-0 z-10 flex h-full w-8 cursor-pointer items-center justify-center text-6xl text-white'
//     }
//     ref={navigationNextRef}
//   >
//     <MdChevronRight />
//   </div>
// </Swiper>
// <div
//   ref={bulletRef}
//   className={
//     'absolute !left-auto !bottom-auto -top-8 right-4 z-10 !w-auto opacity-0 group-hover:opacity-100'
//   }
// ></div> */
//   }

const sources = [
  {
    // id: 's_1',
    // Apigenin
    _taskTemplateSlug: 'take-apigenin',
    title: 'Swanson Apigenin-Bioflavonoid Supplement',
    drugBrand: 'Swanson',
    drugQuantity: 90,
    drugForm: 'capsule',
    drugDose: 50,
    priceUnits: 1186,
    // iso 4217
    priceCurrency: 'usd',
    buyUrl:
      'https://www.amazon.com/Swanson-Apigenin-Prostate-Supplements-Capsules/dp/B001TEIJIQ/ref=sxts_rp_s_1_0?content-id=amzn1.sym.154791ce-888e-46c9-af88-2c4c0ceada82%3Aamzn1.sym.154791ce-888e-46c9-af88-2c4c0ceada82&crid=1WP867EBPFJD8&cv_ct_cx=apigenin&keywords=apigenin&pd_rd_i=B001TEIJIQ&pd_rd_r=6a17f03d-1971-49d0-bdb7-e8a53aac31d2&pd_rd_w=ZKYdl&pd_rd_wg=y2jnk&pf_rd_p=154791ce-888e-46c9-af88-2c4c0ceada82&pf_rd_r=8PABFHQRACRTXMYM9EJY&qid=1666981243&qu=eyJxc2MiOiIzLjY3IiwicXNhIjoiMy4wNiIsInFzcCI6IjIuODYifQ%3D%3D&sprefix=apigenin%2Caps%2C167&sr=1-1-5e1b2986-06e6-4004-a85e-73bfa3ee44fe&th=1'
    // learnMoreSlug: '',
    // createdBy: 'u_0'
  },
  {
    // id: 's_2',
    // Apigenin
    _taskTemplateSlug: 'take-apigenin',
    title: 'Momentous Apigenin',
    drugBrand: 'Momentous',
    drugQuantity: 60,
    drugForm: 'capsule',
    drugDose: 50,
    priceUnits: 2995,
    // iso 4217
    priceCurrency: 'usd',
    buyUrl: 'https://www.livemomentous.com/products/apigenin'
    // learnMoreSlug: '',
    // createdBy: 'u_0' // u_0 is trusted. nothign else is
  }
]

export default sources



import {jsx as _jsx} from "react/jsx-runtime";import {motion} from 'framer-motion'






const TaskCheckbox = (props) => {
  const {onClick, status} = props
  return (
    _jsx('div', { className: 'relative mx-4 flex', onClick: onClick, children: 
      _jsx(motion.button, {
        className: " h-6 w-6 rounded-full border-2 dark:border-dark-primaryContainer"     ,
        animate: status,
        transition: {duration: 0.2},
        variants: {
          unchecked: {},
          checked: {
            backgroundColor: 'var(--color-primaryContainer)'
          }
        },
 children: 
        _jsx('svg', {
          className: "absolute top-0 left-0 dark:text-dark-onSurface"   ,
          viewBox: "0 0 24 24"   ,
          fill: 'none',
          stroke: "currentColor",
          strokeWidth: 3,
          height: 24,
          width: 24,
 children: 
          _jsx(motion.path, {
            initial: status,
            animate: status,
            variants: {
              unchecked: {
                pathLength: 0,
                opacity: 0,
                transition: {
                  pathLength: {
                    delay: 0.025
                  },
                  opacity: {
                    duration: 0.025
                  }
                }
              },
              checked: {
                pathLength: 1,
                opacity: 1,
                transition: {
                  pathLength: {
                    type: 'tween',
                    ease: 'easeOut',
                    delay: 0.05,
                    duration: 0.1
                  },
                  opacity: {
                    delay: 0.05,
                    duration: 0
                  }
                }
              }
            },
            strokeLinecap: "round",
            strokeLinejoin: 'round',
            d: "M5 13l4 4L19 7"   ,}
          )
        })
      })
    })
  )
}

export default TaskCheckbox

import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime";import {MdMoreVert} from '@react-icons/all-files/md/MdMoreVert'

import {useState} from 'react'
import TaskCheckbox from './TaskCheckbox'




const JournalTask = (_props) => {
  const title = 'Take Apigenin'
  const description = '50mg of Swanson apigenin before sleep'
  const [isDone, setIsDone] = useState(false)
  const status = isDone ? 'checked' : 'unchecked'
  return (
    _jsxs('div', {
      className: 
        'relative flex w-80 items-center justify-between rounded-lg p-2 shadow-md dark:bg-dark-surface2 dark:text-dark-onSurface'
      ,
 children: [
      _jsxs('div', { className: 'flex flex-col', children: [
        _jsx('h2', { className: "text-lg font-bold" , children: title})
        , _jsx('h3', { className: "text-xs dark:text-dark-surfaceTint" , children: description})
      ]})
      , _jsx('button', { className: "absolute top-1 right-1"  , children: 
        _jsx(MdMoreVert, {} )
      })
      , _jsx(TaskCheckbox, { onClick: () => setIsDone(!isDone), status: status,} )
    ]})
  )
}

export default JournalTask

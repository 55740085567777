import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParaseFormat from 'dayjs/plugin/customParseFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
;[isoWeek, customParaseFormat, advancedFormat, localeData, weekday, isToday].forEach((plugin) =>
  dayjs.extend(plugin)
)
// dayjs.extend(isoWeek)
// dayjs.extend(customParaseFormat)
// dayjs.extend(advancedFormat)
export default dayjs

import {jsx as _jsx} from "react/jsx-runtime";import React from 'react'
import ReactDOM from 'react-dom/client'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import routes from './routes'

const router = createBrowserRouter(routes)

const root = ReactDOM.createRoot(document.getElementById('root') )
root.render(
  _jsx(React.StrictMode, { children: 
    _jsx(RouterProvider, { router: router,} )
  })
)


import {Environment, Network, QueryResponseCache, RecordSource, Store} from 'relay-runtime'



const CACHE_TTL = 5 * 1000 // 5 seconds, to resolve preloaded results

const noop = () => {
  /*  */
}
export default class Atmosphere extends Environment {
  
  __init() {this.responseCache = new QueryResponseCache({
    size: 100,
    ttl: CACHE_TTL
  })}
  toJSON() {
    return 'hi'
  }
  constructor(isServer) {
    super({
      store: new Store(new RecordSource(), {gcReleaseBufferSize: 10000}),
      network: Network.create(noop),
      isServer
    });Atmosphere.prototype.__init.call(this);Atmosphere.prototype.__init2.call(this);
    this._network = Network.create(this.handleFetch ) 
  }
  __init2() {this.handleFetch = async ( 
    params,
    variables,
    cacheConfig
  ) => {
    const cacheKey = params.id === null ? params.cacheID : params.id
    const body = params.id === null ? {query: params.text, variables} : {id: params.id, variables}
    const {operationKind} = params
    const isQuery = operationKind === 'query'
    const forceFetch = cacheConfig && cacheConfig.force

    if (isQuery && !forceFetch) {
      const fromCache = this.responseCache.get(cacheKey, variables)
      if (fromCache != null) {
        return fromCache
      }
    }

    const response = await fetch(GRAPHQL_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    const contentTypeHeader = response.headers.get('content-type') || ''
    if (!contentTypeHeader.toLowerCase().startsWith('application/json')) {
      throw new Error('Server unavailable')
    }
    const json = await response.json()
    return json 
  }}
}

import {jsx as _jsx} from "react/jsx-runtime"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }
import {Link, useLocation} from 'react-router-dom'









const BottomNavButton = (props) => {
  const {href, activeIcon, activeRoutes, inactiveIcon, name} = props
  const location = useLocation()
  const {pathname} = location
  const isActive = activeRoutes.some((route) => _optionalChain([pathname, 'optionalAccess', _ => _.startsWith, 'call', _2 => _2(route)]))
  return (
    _jsx(Link, { title: name, to: href, children: 
      isActive ? activeIcon : inactiveIcon
    })
  )
}

export default BottomNavButton

import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }

import {graphql, usePreloadedQuery} from 'react-relay'

import useDiscreteTasks from '../hooks/useDiscreteTasks'
import dayjs from '../utils/dayjs'
import JournalTask from './JournalTask'
import WeeklyProgress from './WeeklyProgress'






const Journal = (props) => {
  const {isoDate, queryRef} = props
  const data = usePreloadedQuery(
    graphql`
      query JournalQuery($startAt: Date!, $endAt: Date!) {
        viewer {
          tasks(startAt: $startAt, endAt: $endAt) {
            id
          }
        }
      }
    `,
    queryRef
  )
  const {viewer} = data
  // if (!viewer) throw 'No Viewer'
  // const {tasks} = viewer
  const discreteTasks = useDiscreteTasks(_optionalChain([viewer, 'optionalAccess', _ => _.tasks]))
  const dayjsObj = dayjs(isoDate, 'YYYYMMDD')
  const title = dayjsObj.format('dddd, MMM Do')

  return (
    _jsxs('div', { className: 'flex flex-col p-4', children: [
      _jsx('h2', { className: "text-lg font-bold" , children: title})
      , _jsx(WeeklyProgress, { isoDate: isoDate,} )
      , _jsx('h3', { className: "py-4 font-bold" , children: "Current"})
      , !viewer && _jsx('div', { children: "Mock"})
      , _optionalChain([discreteTasks, 'optionalAccess', _2 => _2.map, 'call', _3 => _3((_discreteTask) => {
        return _jsx(JournalTask, {} )
      })])
      , _jsx(JournalTask, {} )
      , _jsx('div', {})
    ]})
  )
}

export default Journal

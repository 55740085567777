import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {EditorContent, useEditor} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

import {useMemo} from 'react'
import {graphql, useFragment, useMutation} from 'react-relay'







const ProtocolProspectus = (props) => {
  const {protocolKey} = props
  const protocol = useFragment(
    graphql`
      fragment ProtocolProspectus_protocol on Protocol {
        id
        prospectus
      }
    `,
    protocolKey
  )
  const {id: protocolId, prospectus} = protocol

  let content
  try {
    content = JSON.parse(prospectus)
  } catch (e) {
    content = undefined
  }
  const editable = true
  const editor = useEditor({
    extensions: [StarterKit],
    content,
    editable
  })
  const html = useMemo(() => {
    if (!editor) return null
    if (!editable) return editor.getHTML()
    return null
  }, [editable, editor])
  const [commit, isInFlight] = useMutation(graphql`
    mutation ProtocolProspectusMutation($protocolId: ID!, $prospectus: String!) {
      updateProspectus(protocolId: $protocolId, prospectus: $prospectus) {
        error
        protocol {
          prospectus
        }
      }
    }
  `)
  if (!editor) return null
  return (
    _jsxs('div', { className: 'flex flex-col space-y-4 pt-4 pl-4', children: [
      _jsxs('div', { children: [
        _jsx('span', { className: "text-xl font-bold" , children: "Prospectus"})
        , editable ? (
          _jsx(EditorContent, { editor: editor,} )
        ) : (
          _jsx('div', { dangerouslySetInnerHTML: {__html: html},} )
        )
      ]})
      , _jsx('button', {
        disabled: isInFlight,
        onClick: () => {
          const prospectus = _optionalChain([editor, 'optionalAccess', _ => _.getJSON, 'call', _2 => _2()])
          if (!prospectus) return
          commit({variables: {protocolId, prospectus: JSON.stringify(prospectus)}})
        },
 children: "EXPORT"

      })
    ]})
  )
}

export default ProtocolProspectus

import {jsx as _jsx} from "react/jsx-runtime";import DiscoverRoot from './DiscoverRoot'
import JournalRedir from './JournalRedir'
import JournalRoot from './JournalRoot'
import KickoffRoot from './KickoffRoot'
import Layout from './Layout'
import LoginRoot from './LoginRoot'
import ProfileRoot from './ProfileRoot'
import ProtocolRoot from './ProtocolRoot'

const routes = [
  {
    path: '/',
    element: _jsx(Layout, {} ),
    children: [
      {
        path: '/',
        element: _jsx(JournalRedir, {} )
      },
      // {
      //   path: '/create/task-template',
      //   element: <CreateTaskTemplate/>
      // }
      {
        path: '/discover',
        element: _jsx(DiscoverRoot, {} )
      },
      {
        path: '/journal',
        element: _jsx(JournalRedir, {} )
      },
      {
        path: '/journal/:isoDate',
        element: _jsx(JournalRoot, {} )
      },
      {
        path: '/kickoff/:slug',
        element: _jsx(KickoffRoot, {} )
      },
      {
        path: '/login',
        element: _jsx(LoginRoot, {} )
      },
      {
        path: '/profile',
        element: _jsx(ProfileRoot, {} )
      },
      {
        path: '/protocols/:slug',
        element: _jsx(ProtocolRoot, {} )
      }
    ]
  }
]

export default routes

import {jsx as _jsx} from "react/jsx-runtime";import {GrSearch} from '@react-icons/all-files/gr/GrSearch'
import BottomNavButton from './BottomNavButton'
import LeftNavItem from './LeftNavItem'



const NavDiscover = (props) => {
  const {isBottom} = props
  const href = '/discover'
  const activeRoutes = ['/discover', '/protocols', '/kickoff']
  const activeIcon = _jsx(GrSearch, { className: "[&>*]:stroke-dark-onSurface",} )
  const inactiveIcon = _jsx(GrSearch, { className: "[&>*]:stroke-dark-outline",} )
  const name = 'Discover'
  const itemProps = {href, activeRoutes, activeIcon, inactiveIcon, name}
  return isBottom ? _jsx(BottomNavButton, { ...itemProps,} ) : _jsx(LeftNavItem, { ...itemProps,} )
}

export default NavDiscover

import {jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useState} from 'react'
import dayjs from '../utils/dayjs'
import WeeklyProgressDay from './WeeklyProgressDay'





const weekdayArr = Array(7).fill(undefined)
const WeeklyProgress = (props) => {
  const {isoDate} = props
  const dayjsObj = dayjs(isoDate, 'YYYYMMDD')
  const [progress, _setProgess] = useState(0.5)
  return (
    _jsx('div', { className: 'flex justify-between pt-4', children: 
      weekdayArr.map((_, idx) => {
        const weekdayObj = dayjsObj.weekday(idx)
        const weekdayName = weekdayObj.format('dd')
        const date = weekdayObj.date()
        const isToday = weekdayObj.isToday()
        const boldToday = isToday ? 'font-bold' : ''
        const isSame = dayjsObj.isSame(weekdayObj)
        const isSameBg = isSame ? 'dark:bg-dark-surface2 bg-light-surface2' : ''
        return (
          _jsxs('div', {

            className: `relative flex flex-col items-center rounded-lg py-1 ${isSameBg}`,
 children: [
            _jsx(WeeklyProgressDay, { progress: progress,} )
            , _jsx('span', { className: `absolute top-4 text-sm ${boldToday}`, children: weekdayName})
            /* <span>{weekdayName}</span> */
          ]}, date)
        )
      })
    })
  )
}

const variants = {
  enter: ({width, direction}) => ({x: direction * width}),
  exit: ({width, direction}) => ({x: -direction * width}),
  center: {x: 0}
}
export default WeeklyProgress

 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {useLayoutEffect, useState} from 'react'

const useTransformRestoration = (
  cacheKey,
  ref,
  isXAxis
) => {
  const [mountedAt] = useState(() => {
    return Date.now()
  })
  const [initialOffset] = useState(() => {
    const val = window.sessionStorage.getItem(cacheKey)
    // window.sessionStorage.removeItem(cacheKey)
    return parseInt(val, 10) || 0
  })
  useLayoutEffect(() => {
    return () => {
      // handle react strict mode double mount
      if (Date.now() - mountedAt < 100) return
      const el = ref.current
      if (!el) return
      const transformStr = el.style.transform
      const axis = isXAxis ? 'X' : 'Y'
      const regexpStr = `translate${axis}\\((\\S+)px\\)`
      const translateStrRegex = new RegExp(regexpStr)
      const translateNum = transformStr.match(translateStrRegex)
      const offset = _optionalChain([translateNum, 'optionalAccess', _ => _[1]])
      const safeOffset = parseInt(offset, 10) || 0
      window.sessionStorage.setItem(cacheKey, String(safeOffset))
    }
  }, [])
  return initialOffset
}

export default useTransformRestoration

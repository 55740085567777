

























const node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RowCategory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Protocol",
      "kind": "LinkedField",
      "name": "protocols",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RowPosterProtocol"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProtocolCategory",
  "abstractKey": null
};

(node ).hash = "a5fcf31abb3a73e6aaf9e70d3d11ad2e";

export default node;

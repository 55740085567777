


























const node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endAt"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startAt"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "endAt",
      "variableName": "endAt"
    },
    {
      "kind": "Variable",
      "name": "startAt",
      "variableName": "startAt"
    }
  ],
  "concreteType": "Task",
  "kind": "LinkedField",
  "name": "tasks",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "JournalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "JournalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5cdae9da44728538edf14443528b1553",
    "id": null,
    "metadata": {},
    "name": "JournalQuery",
    "operationKind": "query",
    "text": "query JournalQuery(\n  $startAt: Date!\n  $endAt: Date!\n) {\n  viewer {\n    tasks(startAt: $startAt, endAt: $endAt) {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node ).hash = "5c3886f948c4f3017a588214955a7c1d";

export default node;

// import resolveConfig from 'tailwindcss/resolveConfig'
// import tailwindConfig from '../../tailwind.config.js'

// const fullConfig = resolveConfig(tailwindConfig as any)
// const theme = fullConfig.theme as typeof tailwindConfig.theme
const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  light: {
    primary: '#006689',
    onPrimary: '#ffffff',
    primaryContainer: '#c2e8ff',
    onPrimaryContainer: '#001e2c',
    secondary: '#166d23',
    onSecondary: '#ffffff',
    secondaryContainer: '#a0f79b',
    onSecondaryContainer: '#002204',
    tertiary: '#605a7d',
    onTertiary: '#ffffff',
    tertiaryContainer: '#e6deff',
    onTertiaryContainer: '#1c1736',
    error: '#ba1a1a',
    errorContainer: '#ffdad6',
    onError: '#ffffff',
    onErrorContainer: '#410002',
    background: '#fbfcfe',
    onBackground: '#191c1e',
    surface: '#fbfcfe',
    surface1: '#EEF4F8',
    surface2: '#E7F0F5',
    surface3: '#DFEBF1',
    surface4: '#DCE9EF',
    surface5: '#D8E6ED',
    onSurface: '#191c1e',
    surfaceVariant: '#dce3e9',
    onSurfaceVariant: '#41484d',
    outline: '#71787d',
    inverseOnSurface: '#f0f1f3',
    inverseSurface: '#2e3133',
    inversePrimary: '#78d1ff',
    shadow: '#000000',
    surfaceTint: '#006689',
    outlineVariant: '#c0c7cd',
    scrim: '#000000'
  },
  dark: {
    primary: '#78d1ff',
    onPrimary: '#003549',
    primaryContainer: '#004d68',
    onPrimaryContainer: '#c2e8ff',
    secondary: '#85da81',
    onSecondary: '#00390a',
    secondaryContainer: '#005313',
    onSecondaryContainer: '#a0f79b',
    tertiary: '#c9c1ea',
    onTertiary: '#312c4c',
    tertiaryContainer: '#484264',
    onTertiaryContainer: '#e6deff',
    error: '#ffb4ab',
    errorContainer: '#93000a',
    onError: '#690005',
    onErrorContainer: '#ffdad6',
    background: '#191c1e',
    onBackground: '#e1e2e5',
    surface: '#191c1e',
    surface1: '#1E2529',
    surface2: '#212B30',
    surface3: '#233037',
    surface4: '#243239',
    surface5: '#26353E',
    onSurface: '#e1e2e5',
    surfaceVariant: '#41484d',
    onSurfaceVariant: '#c0c7cd',
    outline: '#8a9297',
    inverseOnSurface: '#191c1e',
    inverseSurface: '#e1e2e5',
    inversePrimary: '#006689',
    shadow: '#000000',
    surfaceTint: '#78d1ff',
    outlineVariant: '#41484d',
    scrim: '#000000'
  }
  // primary: {
  //   10: '#001e2c',
  //   20: '#003549',
  //   30: '#004d68',
  //   40: '#006689',
  //   50: '#0080ab',
  //   60: '#209bcc',
  //   70: '#4ab6e8',
  //   80: '#78d1ff',
  //   90: '#c2e8ff',
  //   95: '#e2f3ff',
  //   99: '#fbfcff'
  // },
  // secondary: {
  //   10: '#002204',
  //   20: '#00390a',
  //   30: '#005313',
  //   40: '#166d23',
  //   50: '#358739',
  //   60: '#50a251',
  //   70: '#6abe69',
  //   80: '#85da81',
  //   90: '#a0f79b',
  //   95: '#c8ffc0',
  //   99: '#f6fff0'
  // },
  // tertiary: {
  //   10: '#1c1736',
  //   20: '#312c4c',
  //   30: '#484264',
  //   40: '#605a7d',
  //   50: '#797297',
  //   60: '#938cb2',
  //   70: '#aea6cd',
  //   80: '#c9c1ea',
  //   90: '#e6deff',
  //   95: '#f4eeff',
  //   99: '#fffbff'
  // },
  // neutral: {
  //   10: '#191c1e',
  //   20: '#2e3133',
  //   30: '#454749',
  //   40: '#5c5f61',
  //   50: '#757779',
  //   60: '#8f9193',
  //   70: '#aaabae',
  //   80: '#c5c6c9',
  //   90: '#e1e2e5',
  //   95: '#f0f1f3',
  //   99: '#fbfcfe'
  // },
  // variant: {
  //   10: '#151d21',
  //   20: '#2a3136',
  //   30: '#41484d',
  //   40: '#585f64',
  //   50: '#71787d',
  //   60: '#8a9297',
  //   70: '#a5acb2',
  //   80: '#c0c7cd',
  //   90: '#dce3e9',
  //   95: '#ebf2f8',
  //   99: '#fbfcff'
  // },
  // error: {
  //   10: '#410002',
  //   20: '#690005',
  //   30: '#93000a',
  //   40: '#ba1a1a',
  //   50: '#de3730',
  //   60: '#ff5449',
  //   70: '#ff897d',
  //   80: '#ffb4ab',
  //   90: '#ffdad6',
  //   95: '#ffedea',
  //   99: '#fffbff'
  // }
  // light: {
  //   primary: '#166d23',
  //   onPrimary: '#ffffff',
  //   primaryContainer: '#a0f79b',
  //   onPrimaryContainer: '#002204',
  //   secondary: '#00658b',
  //   onSecondary: '#ffffff',
  //   secondaryContainer: '#c5e7ff',
  //   onSecondaryContainer: '#001e2d',
  //   tertiary: '#6f46b7',
  //   onTertiary: '#ffffff',
  //   tertiaryContainer: '#ebdcff',
  //   onTertiaryContainer: '#260059',
  //   error: '#ba1a1a',
  //   errorContainer: '#ffdad6',
  //   onError: '#ffffff',
  //   onErrorContainer: '#410002',
  //   background: '#fcfcff',
  //   onBackground: '#001d32',
  //   surface: '#fcfcff',
  //   surface1: '#F0F4F4',
  //   surface2: '#EAF1EE',
  //   surface3: '#E2ECE7',
  //   surface4: '#E0EAE4',
  //   surface5: '#DBE7E0',
  //   onSurface: '#001d32',
  //   surfaceVariant: '#dee5d8',
  //   onSurfaceVariant: '#424940',
  //   outline: '#72796f',
  //   inverseOnSurface: '#e8f2ff',
  //   inverseSurface: '#003352',
  //   inversePrimary: '#85da81',
  //   shadow: '#000000',
  //   surfaceTint: '#166d23',
  //   surfaceTintColor: '#166d23'
  // },
  // dark: {
  //   primary: '#85da81',
  //   onPrimary: '#00390a',
  //   primaryContainer: '#005313',
  //   onPrimaryContainer: '#a0f79b',
  //   secondary: '#7ed0ff',
  //   onSecondary: '#00344a',
  //   secondaryContainer: '#004c6a',
  //   onSecondaryContainer: '#c5e7ff',
  //   tertiary: '#d3bbff',
  //   onTertiary: '#3f0987',
  //   tertiaryContainer: '#562c9e',
  //   onTertiaryContainer: '#ebdcff',
  //   error: '#ffb4ab',
  //   errorContainer: '#93000a',
  //   onError: '#690005',
  //   onErrorContainer: '#ffdad6',
  //   background: '#001d32',
  //   onBackground: '#cde5ff',
  //   surface: '#001d32',
  //   surface1: '#001d32',
  //   surface2: '#072735',
  //   surface3: '#0B2C38',
  //   surface4: '#0F323B',
  //   surface5: '#10333B',
  //   onSurface: '#cde5ff',
  //   surfaceVariant: '#424940',
  //   onSurfaceVariant: '#c2c9bd',
  //   outline: '#8c9388',
  //   inverseOnSurface: '#001d32',
  //   inverseSurface: '#cde5ff',
  //   inversePrimary: '#166d23',
  //   shadow: '#000000',
  //   surfaceTint: '#85da81',
  //   surfaceTintColor: '#85da81'
  // },
  // primary: {
  //   10: '#002204',
  //   20: '#00390a',
  //   30: '#005313',
  //   40: '#166d23',
  //   50: '#358739',
  //   60: '#50a251',
  //   70: '#6abe69',
  //   80: '#85da81',
  //   90: '#a0f79b',
  //   95: '#c8ffc0',
  //   99: '#f6fff0'
  // },
  // secondary: {
  //   10: '#001e2d',
  //   20: '#00344a',
  //   30: '#004c6a',
  //   40: '#00658b',
  //   50: '#0080ae',
  //   60: '#289ace',
  //   70: '#4eb5ea',
  //   80: '#7ed0ff',
  //   90: '#c5e7ff',
  //   95: '#e3f3ff',
  //   99: '#fbfcff'
  // },
  // tertiary: {
  //   10: '#260059',
  //   20: '#3f0987',
  //   30: '#562c9e',
  //   40: '#6f46b7',
  //   50: '#8960d3',
  //   60: '#a37bef',
  //   70: '#bc99ff',
  //   80: '#d3bbff',
  //   90: '#ebdcff',
  //   95: '#f7edff',
  //   99: '#fffbff'
  // },
  // neutral: {
  //   10: '#001d32',
  //   20: '#003352',
  //   30: '#004a75',
  //   40: '#006399',
  //   50: '#1f7dba',
  //   60: '#4497d6',
  //   70: '#63b2f3',
  //   80: '#95ccff',
  //   90: '#cde5ff',
  //   95: '#e8f2ff',
  //   99: '#fcfcff'
  // },
  // variant: {
  //   10: '#171d16',
  //   20: '#2c322a',
  //   30: '#424940',
  //   40: '#5a6057',
  //   50: '#72796f',
  //   60: '#8c9388',
  //   70: '#a7ada2',
  //   80: '#c2c9bd',
  //   90: '#dee5d8',
  //   95: '#ecf3e6',
  //   99: '#f8fef2'
  // },
  // error: {
  //   10: '#410002',
  //   20: '#690005',
  //   30: '#93000a',
  //   40: '#ba1a1a',
  //   50: '#de3730',
  //   60: '#ff5449',
  //   70: '#ff897d',
  //   80: '#ffb4ab',
  //   90: '#ffdad6',
  //   95: '#ffedea',
  //   99: '#fffbff'
  // }
  // blue: {
  //   050: '#EBF8FF',
  //   100: '#D1EEFC',
  //   200: '#A7D8F0',
  //   300: '#7CC1E4',
  //   400: '#55AAD4',
  //   500: '#3994C1',
  //   600: '#2D83AE',
  //   700: '#1D6F98',
  //   800: '#166086',
  //   900: '#0B4F71'
  // },
  // green: {
  //   050: '#E3F9E5',
  //   100: '#C1EAC5',
  //   200: '#A3D9A5',
  //   300: '#7BC47F',
  //   400: '#57AE5B',
  //   500: '#3F9142',
  //   600: '#2F8132',
  //   700: '#207227',
  //   800: '#0E5814',
  //   900: '#05400A',
  //   // #121212 blended with 8% green-500
  //   darkbg: '#151C15'
  // },
  // gray: {
  //   050: '#F5F7FA',
  //   100: '#E4E7EB',
  //   200: '#CBD2D9',
  //   300: '#9AA5B1',
  //   400: '#7B8794',
  //   500: '#616E7C',
  //   600: '#52606D',
  //   700: '#3E4C59',
  //   800: '#323F4B',
  //   900: '#1F2933'
  // },
  // purple: {
  //   050: '#EAE2F8',
  //   100: '#CFBCF2',
  //   200: '#A081D9',
  //   300: '#8662C7',
  //   400: '#724BB7',
  //   500: '#653CAD',
  //   600: '#51279B',
  //   700: '#421987',
  //   800: '#34126F',
  //   900: '#240754'
  // },
  // red: {
  //   050: '#FFEEEE',
  //   100: '#FACDCD',
  //   200: '#F29B9B',
  //   300: '#E66A6A',
  //   400: '#D64545',
  //   500: '#BA2525',
  //   600: '#A61B1B',
  //   700: '#911111',
  //   800: '#780A0A',
  //   900: '#610404'
  // },
  // yellow: {
  //   050: '#FFFAEB',
  //   100: '#FCEFC7',
  //   200: '#F8E3A3',
  //   300: '#F9DA8B',
  //   400: '#F7D070',
  //   500: '#E9B949',
  //   600: '#C99A2E',
  //   700: '#A27C1A',
  //   800: '#7C5E10',
  //   900: '#513C06'
  // }
}
module.exports = {colors}

























const node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscoverQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProtocolCategory",
        "kind": "LinkedField",
        "name": "categories",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RowCategory"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DiscoverQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProtocolCategory",
        "kind": "LinkedField",
        "name": "categories",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Protocol",
            "kind": "LinkedField",
            "name": "protocols",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "backgroundSrc",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "track",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trackAvatar",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "883d51c78a04ba4ef3467a5a25dcf604",
    "id": null,
    "metadata": {},
    "name": "DiscoverQuery",
    "operationKind": "query",
    "text": "query DiscoverQuery {\n  categories {\n    id\n    ...RowCategory\n  }\n}\n\nfragment RowCategory on ProtocolCategory {\n  title\n  slug\n  protocols {\n    id\n    ...RowPosterProtocol\n  }\n}\n\nfragment RowPosterProtocol on Protocol {\n  id\n  title\n  slug\n  backgroundSrc\n  track\n  trackAvatar\n}\n"
  }
};
})();

(node ).hash = "d57d4ffcf462509cc3e68bbd2aafd798";

export default node;

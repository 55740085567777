import {jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs} from "react/jsx-runtime";
import {useState} from 'react'
import {RelayEnvironmentProvider} from 'react-relay'
import {Outlet} from 'react-router-dom'
import serverAtmosphere from '../ssg/serverAtmosphere'
import '../styles/global.css'
import Atmosphere from '../utils/Atmosphere'
import BottomNav from './BottomNav'
import LeftNav from './LeftNav'

const atmosphere = new Atmosphere()



const Layout = (props) => {
  const {C} = props
  const [environment] = useState(() => {
    return typeof window === 'undefined' ? serverAtmosphere : atmosphere
  })
  return (
    _jsx(_Fragment, { children: 
      _jsx('div', {
        id: 'app',
        className: `flex h-screen overflow-hidden dark:bg-dark-surface dark:text-dark-onSurface`,
 children: 
        _jsxs(RelayEnvironmentProvider, { environment: environment, children: [
          _jsx(LeftNav, {} )
          , _jsxs('div', { className: "flex w-full flex-col"  , children: [
            /* relative so 100% height doesn't cover bottom nav */
            _jsx('div', { id: 'main', className: 'relative flex w-full  grow overflow-auto', children: 
              C ? C : _jsx(Outlet, {} )
            })
            , _jsx(BottomNav, {} )
          ]})
        ]})
      })
    })
  )
}

export default Layout

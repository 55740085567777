import {jsx as _jsx} from "react/jsx-runtime";import dayjs from 'dayjs'
import {Suspense, useEffect} from 'react'
import {useQueryLoader} from 'react-relay'

import JournalQueryNode from '../../__generated__/relay/JournalQuery.graphql'
import Journal from './Journal'

const JournalRoot = () => {
  const isoDate = typeof location !== 'undefined' && location.href.split('/')[4]
  const [queryRef, loadQuery] = useQueryLoader(JournalQueryNode)
  useEffect(() => {
    if (!isoDate) return
    const startAt = dayjs(isoDate, 'YYYYMMDD')
    const endAt = startAt.endOf('day')
    loadQuery({endAt, startAt})
  }, [])
  return (
    _jsx(Suspense, { fallback: "Loading...", children: 
      queryRef && isoDate && _jsx(Journal, { queryRef: queryRef, isoDate: isoDate,} )
    })
  )
}

export default JournalRoot

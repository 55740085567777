import {jsx as _jsx} from "react/jsx-runtime";import {Suspense, useEffect} from 'react'
import {useQueryLoader} from 'react-relay'

import DiscoverQueryNode from '../../__generated__/relay/DiscoverQuery.graphql'
import Kickoff from './Kickoff'

const KickoffRoot = () => {
  const [queryRef, loadQuery] = useQueryLoader(DiscoverQueryNode)
  useEffect(() => {
    loadQuery({})
  }, [])
  return _jsx(Suspense, { fallback: 'Loading...', children: queryRef && _jsx(Kickoff, { queryRef: queryRef,} )})
}

export default KickoffRoot

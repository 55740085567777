import {jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";import {m} from 'framer-motion'
import React from 'react'
import {graphql, useFragment} from 'react-relay'
import {Link} from 'react-router-dom'







const RowPoster = React.memo((props) => {
  const {protocolKey, isDragging} = props
  const protocol = useFragment(
    graphql`
      fragment RowPosterProtocol on Protocol {
        id
        title
        slug
        backgroundSrc
        track
        trackAvatar
      }
    `,
    protocolKey
  )

  const {id, slug, title, backgroundSrc, track, trackAvatar} = protocol
  const onClick = (e) => {
    if (isDragging) {
      e.preventDefault()
    }
  }
  return (
    _jsx(Link, {
      onClick: onClick,
      // href={`/protocols?slug=${slug}`}
      to: `/protocols/${slug}`,
      draggable: false,
      className: 'select-none',
 children: 
      _jsxs('div', { draggable: false, className: 'relative min-w-max select-none rounded', children: [
        _jsx('img', {
          draggable: false,
          alt: title,
          className: 'rounded-xl',
          src: backgroundSrc,
          width: 208,

})
        , _jsxs(m.div, { className: 'flex pt-2 text-sm text-light-onSurface dark:text-dark-onSurface', children: [
          _jsx('img', {
            className: 'mr-2 rounded-full',
            draggable: false,
            alt: track,
            src: trackAvatar,
            width: 40,
            height: 40,}
          )
          , _jsxs('div', { className: "flex select-none flex-col"  , draggable: false, children: [
            _jsx('div', { children: title})
            , _jsx('div', { className: "text-xs font-light dark:text-dark-onSurfaceVariant"  , children: track})
          ]})
        ]})
      ]})
    })
  )
})

export default RowPoster

import {jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";import * as ScrollArea from '@radix-ui/react-scroll-area'
// import graphql from 'babel-plugin-relay/macro'
import {useRef} from 'react'


import NewTaskTemplate from './NewTaskTemplate'




const Kickoff = (props) => {
  const {queryRef} = props
  console.log(queryRef)
  const ref = useRef(null)

  return (
    _jsx('div', { className: "relative flex h-full w-full flex-col"    , children: 
      /* https://github.com/framer/motion/issues/1524 */
      _jsxs(ScrollArea.Root, { type: "hover", className: "overflow-hidden", children: [
        _jsxs(ScrollArea.Viewport, {
          ref: ref,
          className: 'flex h-full w-full flex-col [&>*]:!flex [&>*]:!flex-col',
 children: [
          _jsx('div', { className: "flex h-14 shrink-0"  ,})
          , _jsx('header', { className: "absolute top-0 left-0 z-10 w-full p-4 text-center font-bold backdrop-blur-xl"        , children: 
            _jsx('h1', { children: "Kickoff"})
          })
          , _jsx(NewTaskTemplate, { queryRef: queryRef,} )
        ]})
        , _jsx(ScrollArea.Scrollbar, {
          orientation: "vertical",
          className: "!top-14 flex w-2 touch-none select-none opacity-50 transition-opacity"      ,
 children: 
          _jsx(ScrollArea.Thumb, { className: "flex-1 rounded-lg dark:bg-dark-surfaceTint"  ,} )
        })
      ]})
    })
  )
}

export default Kickoff

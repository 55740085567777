import {jsx as _jsx} from "react/jsx-runtime";
import {Suspense, useEffect} from 'react'
import {useQueryLoader} from 'react-relay'
import {useParams} from 'react-router-dom'

import ProtocolQueryNode from '../../__generated__/relay/ProtocolQuery.graphql'
import Protocol from './Protocol'

const ProtocolRoot = () => {
  const params = useParams()
  const {slug} = params
  const [queryRef, loadQuery] = useQueryLoader(ProtocolQueryNode)
  useEffect(() => {
    if (!slug) return
    loadQuery({slug})
  }, [])
  if (!slug) return null
  return _jsx(Suspense, { fallback: "Loading...", children: queryRef && _jsx(Protocol, { queryRef: queryRef,} )})
}

export default ProtocolRoot

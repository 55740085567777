import {jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";// import graphql from 'babel-plugin-relay/macro'
import {GrFormClock} from '@react-icons/all-files/gr/GrFormClock'
import {AnimatePresence, motion} from 'framer-motion'
import {useState} from 'react'

import useMeasure from 'react-use-measure'

import sources from '../data/sources'









const NewTaskTemplate = (props) => {
  const {queryRef} = props
  console.log(queryRef)
  // const {register, handleSubmit, watch, formState} = useForm<Inputs>({
  //   defaultValues: {
  //     dueAtTime: '',
  //     dueAtDate: ''
  //   }
  // })
  // const ref = useRef<HTMLDivElement>(null)

  const [direction, setDirection] = useState(1)
  const [ref, {width}] = useMeasure()
  // const sources = [] as any[]
  const [sourceIdx, setSourceIdx] = useState(0)
  const setSource = (delta) => () => {
    const nextValue = sourceIdx + delta
    if (nextValue >= sources.length || nextValue < 0) return
    setDirection(delta)
    setSourceIdx(nextValue)
  }
  // const source = sources[sourceIdx]!
  const source = sources[0]
  const {title: sourceTitle, drugDose, drugQuantity, buyUrl} = source
  return (
    _jsx('div', { className: "card card-compact w-96 bg-base-100 shadow-xl"    , children: 
      _jsxs('div', { className: "card-body", children: [
        _jsx('h2', { className: "card-title", children: "Rate your sleep"  })
        , _jsxs('div', { className: "flex", children: [
          _jsx('div', { children: 
            _jsx(GrFormClock, {} )
          })
          , _jsx('input', { type: "text", placeholder: "Type here" , className: "input w-full max-w-xs"  ,} )
        ]})
        , _jsx('p', { children: "If a dog chews shoes whose shoes does he choose?"         })
        , _jsx('div', { className: "card-actions justify-end" , children: 
          _jsx('button', { className: "btn-primary btn" , children: "Buy Now" })
        })
        , _jsx('div', { className: "mt-8 flex justify-center"  , children: 
          _jsxs('div', {
            ref: ref,
            className: 
              'relative flex h-48 w-full items-center justify-around overflow-hidden text-sm '
            ,
 children: [
            _jsx('button', { className: 'left-0 top-1/2', onClick: setSource(-1), children: "Prev"

            })
            , _jsx(AnimatePresence, { custom: {direction, width}, children: 
              _jsxs(motion.div, {

                variants: variants,
                initial: 'enter',
                animate: 'center',
                exit: 'exit',
                transition: {duration: 0.3},
                custom: {direction, width},
                className: "bg-blue-500 absolute flex h-28 w-48 flex-col justify-center rounded text-white"        ,
 children: [
                _jsxs('div', { className: "flex flex-1 flex-col"  , children: [
                  _jsx('span', { className: "text-ellipsis text-base" , children: sourceTitle})
                  , _jsxs('span', { className: "text-xs", children: [drugDose, "mg"]})
                  , _jsxs('span', { className: "text-xs", children: ["Quantity: " , drugQuantity]})
                ]})
                , _jsx('div', { className: "border-t text-center" , children: 
                  _jsx('a', { href: buyUrl, target: "_blank", children: "Check price"

                  })
                })
              ]}, sourceIdx)
            })
            , _jsx('button', { onClick: setSource(1), children: "Next"})
          ]})
        })
      ]})
    })
  )
}

const variants = {
  enter: ({width, direction}) => ({x: direction * width}),
  exit: ({width, direction}) => ({x: -direction * width}),
  center: {x: 0}
}
export default NewTaskTemplate

 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {useLayoutEffect, useState} from 'react'

const useScrollRestoration = (
  cacheKey,
  ref,
  isXAxis
) => {
  const [mountedAt] = useState(() => {
    return Date.now()
  })
  useLayoutEffect(() => {
    const val = window.sessionStorage.getItem(cacheKey)
    const offset = parseInt(val, 10) || 0
    _optionalChain([ref, 'access', _ => _.current, 'optionalAccess', _2 => _2.scrollTo, 'call', _3 => _3({
      top: isXAxis ? 0 : offset,
      left: isXAxis ? offset : 0,
      behavior: 'instant' 
    })])
    return () => {
      const el = ref.current
      if (!el) return
      const offset = isXAxis ? el.scrollLeft : el.scrollTop
      window.sessionStorage.setItem(cacheKey, String(offset))
    }
  }, [])
}

export default useScrollRestoration

import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime";

import {useForm} from 'react-hook-form'











const Login = (_props) => {
  const {register, handleSubmit, watch, formState} = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  })
  const {errors} = formState
  const onSubmit = (data) => {
    console.log({data})
  }
  return (
    _jsxs('div', { className: "relative flex h-full w-full flex-col"    , children: [
      _jsx('div', { className: "flex h-14 shrink-0"  ,})
      , _jsx('header', { className: "absolute top-0 left-0 z-10 w-full p-4 text-center font-bold backdrop-blur-xl"        , children: 
        _jsx('h1', { children: "Login"})
      })
      , _jsx('main', { className: "flex h-full w-full items-center justify-center"    , children: 
        _jsx('div', { className: "card w-full max-w-sm shrink-0 bg-base-200 shadow-2xl"     , children: 
          _jsx('div', { className: "card-body", children: 
            _jsxs('form', { onSubmit: handleSubmit(onSubmit), children: [
              _jsxs('div', { className: "form-control", children: [
                _jsx('label', { className: "label", children: 
                  _jsx('span', { className: "label-text", children: "Email"})
                })
                , _jsx('input', {
                  ...register('email', {
                    required: 'Email is required',
                    maxLength: {
                      message: 'Email is too long',
                      value: 500
                    }
                  }),
                  type: "email",
                  placeholder: "email",
                  className: "input-bordered input" ,}
                )
              ]})
              , _jsxs('div', { className: "form-control", children: [
                _jsx('label', { className: "label", children: 
                  _jsx('span', { className: "label-text", children: "Password"})
                })
                , _jsx('input', {
                  ...register('password', {
                    required: 'Password is required',
                    maxLength: {
                      message: 'Password is too long',
                      value: 500
                    },
                    minLength: {
                      message: 'Password is too short',
                      value: 6
                    }
                  }),
                  type: "password",
                  placeholder: "password",
                  className: "input-bordered input" ,}
                )
                , errors.password && _jsx('div', { children: errors.password.message})
                , _jsx('label', { className: "label justify-end" , children: 
                  _jsx('a', { href: "#", className: "link-hover label-text-alt link"  , children: "Forgot password?"

                  })
                })
              ]})
              , _jsx('div', { className: "form-control mt-6" , children: 
                _jsx('button', { className: "btn-primary btn" , type: "submit", children: "Login"

                })
              })
            ]})
          })
        })
      })
    ]})
  )
}

export default Login

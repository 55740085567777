import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime";import NavDiscover from './NavDiscover'
import NavJournal from './NavJournal'
import NavProfile from './NavProfile'

const BottomNav = () => {
  return (
    _jsxs('div', { className: "flex h-20 w-full shrink-0 items-center justify-around bg-light-surface2 text-4xl dark:bg-dark-surface dark:text-dark-onSurface md:hidden"          , children: [
      _jsx(NavJournal, { isBottom: true,} )
      , _jsx(NavDiscover, { isBottom: true,} )
      , _jsx(NavProfile, { isBottom: true,} )
    ]})
  )
}

export default BottomNav

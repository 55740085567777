



























const node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "prospectus"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "protocolId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "prospectus",
    "variableName": "prospectus"
  },
  {
    "kind": "Variable",
    "name": "protocolId",
    "variableName": "protocolId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prospectus",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProtocolProspectusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateProspectusPayload",
        "kind": "LinkedField",
        "name": "updateProspectus",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Protocol",
            "kind": "LinkedField",
            "name": "protocol",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProtocolProspectusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateProspectusPayload",
        "kind": "LinkedField",
        "name": "updateProspectus",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Protocol",
            "kind": "LinkedField",
            "name": "protocol",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f25eeda533c36a2f4468ecd7b90f516",
    "id": null,
    "metadata": {},
    "name": "ProtocolProspectusMutation",
    "operationKind": "mutation",
    "text": "mutation ProtocolProspectusMutation(\n  $protocolId: ID!\n  $prospectus: String!\n) {\n  updateProspectus(protocolId: $protocolId, prospectus: $prospectus) {\n    error\n    protocol {\n      prospectus\n      id\n    }\n  }\n}\n"
  }
};
})();

(node ).hash = "5ad10c1b3a73335b852728442c48732c";

export default node;

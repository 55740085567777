import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime";



const WeeklyProgressDay = (props) => {
  const {progress} = props
  const radius = 24
  const width = 6
  const normalizedRadius = radius - width
  const circumference = Math.round(normalizedRadius * 2 * Math.PI * 1000) / 1000
  return (
    _jsxs('svg', { className: 'h-12 w-12', children: [
      _jsx('defs', { children: 
        _jsxs('linearGradient', {
          id: "weekdaygrad",
          // gradientTransform={'rotate(90)'}
          x1: "1",
          y1: "0",
          x2: "0",
          y2: "1",
 children: [
          _jsx('stop', { offset: "0%", className: '[stop-color:theme(colors.dark.tertiaryContainer)]',} )
          , _jsx('stop', { offset: "100%", className: '[stop-color:theme(colors.dark.tertiary)]',} )
        ]})
      })
      , _jsx('circle', {
        className: "fill-transparent dark:stroke-dark-surface3" ,
        strokeDasharray: circumference,
        strokeWidth: width,
        stroke: 'gray',
        r: normalizedRadius,
        cx: radius,
        cy: radius,}
      )
      , _jsx('circle', {
        className: "origin-center -rotate-90  fill-transparent transition-[stroke-dashoffset] duration-300"     ,
        strokeLinecap: "round",
        strokeDasharray: circumference,
        strokeWidth: width,
        stroke: 'url(#weekdaygrad)',
        style: {
          strokeDashoffset: circumference - progress * circumference
        },
        r: normalizedRadius,
        cx: radius,
        cy: radius,}
      )
    ]})
  )
}

export default WeeklyProgressDay
